// Documentation: https://next.router.vuejs.org/
import { createRouter, createWebHistory } from 'vue-router'

const routes = [
	// frontend routes
	{
		path: '/',
		component: () =>
			import(
				/* webpackChunkName: "frontend" */ '../layouts/frontend.vue'
			),
		children: [
			{
				path: '',
				name: 'home',
				component: () =>
					import(
						/* webpackChunkName: "home" */ '../frontend/home.vue'
					)
			},
			{
				path: '/club',
				name: 'club',
				component: () =>
					import(
						/* webpackChunkName: "home" */ '../frontend/club.vue'
					)
				,
			},
			{
				path: '/club/payment-details/:id',
				name: 'payment-details',
				component: () =>
					import(
						/* webpackChunkName: "home" */ '../frontend/payment-details.vue'
					)
				,
			},
			{
				path: '/item/:id',
				name: 'item',
				component: () =>
					import(
						/* webpackChunkName: "home" */ '../frontend/item.vue'
					)
				,
			},
			{
				path: '/add-to-club',
				name: 'add-to-club',
				component: () =>
					import(
						/* webpackChunkName: "custom" */ '../frontend/add-to-club.vue'
					)
			},
			{
				path: '/shop',
				name: 'shop',
				component: () =>
					import(
						/* webpackChunkName: "shop" */ '../frontend/shop.vue'
					)
			},
			{
				path: '/shop/:url',
				name: 'product',
				component: () =>
					import(
						/* webpackChunkName: "product" */ '../frontend/product.vue'
					)
			},
			{
				path: '/product/:id',
				name: 'product-view',
				component: () =>
					import(
						/* webpackChunkName: "product-view" */ '../frontend/product.vue'
					)
			},
			{
				path: '/account',
				name: 'account',
				meta: { requiresAuth: false },
				component: () =>
					import(
						/* webpackChunkName: "account" */ '../frontend/account.vue'
					)
			},
			{
				path: '/cart',
				name: 'cart',
				component: () =>
					import(
						/* webpackChunkName: "cart" */ '../frontend/cart.vue'
					)
			},
			{
				path: '/cart/info',
				name: 'cart-info',
				component: () =>
					import(
						/* webpackChunkName: "cart-info" */ '../frontend/cart-info.vue'
					)
			},
			{
				path: '/cart/payment',
				name: 'cart-payment',
				component: () =>
					import(
						/* webpackChunkName: "cart-payment" */ '../frontend/cart-payment.vue'
					)
			},
			{
				path: '/cart/success',
				name: 'cart-success',
				component: () =>
					import(
						/* webpackChunkName: "success" */ '../frontend/cart-success.vue'
					)
			},
			{
				path: '/cart/failed',
				name: 'cart-failed',
				component: () =>
					import(
						/* webpackChunkName: "failed" */ '../frontend/cart-failed.vue'
					)
			},
			{
				path: '/:page',
				name: 'page',
				component: () =>
					import(
						/* webpackChunkName: "page" */ '../frontend/page.vue'
					)
			},
			{
				path: '/gift/:id',
				name: 'gift',
				component: () =>
					import(
						/* webpackChunkName: "gift" */ '../frontend/gift.vue'
					)
			},
			{
				path: '/custom',
				name: 'custom',
				component: () =>
					import(
						/* webpackChunkName: "custom" */ '../frontend/custom.vue'
					)
			},
		]
	},

	// backend routes
	{
		path: '/admin',
		name: 'admin',
		meta: { requiresAdmin: true },
		component: () =>
			import(/* webpackChunkName: "backend" */ '../layouts/backend.vue'),
		children: [
			{
				path: '',
				name: 'dashboard',
				component: () =>
					import(
						/* webpackChunkName: "dashboard" */ '../backend/orders.vue'
					)
			},
			{
				path: 'orders',
				name: 'orders',
				component: () =>
					import(
						/* webpackChunkName: "orders" */ '../backend/orders.vue'
					)
			},
			{
				path: 'orders-club',
				name: 'orders-club',
				component: () =>
					import(
						/* webpackChunkName: "orders-club" */ '../backend/orders-club.vue'
					)
			},
			{
				path: 'orders/:id',
				name: 'order',
				component: () =>
					import(
						/* webpackChunkName: "order" */ '../backend/order.vue'
					)
			},
			{
				path: 'orders-club/:id',
				name: 'order-club',
				component: () =>
					import(
						/* webpackChunkName: "order-club" */ '../backend/order-club.vue'
					)
			},
			{
				path: 'invoices',
				name: 'invoices',
				component: () =>
					import(
						/* webpackChunkName: "invoices" */ '../backend/invoices.vue'
					)
			},
			{
				path: 'products',
				name: 'products',
				component: () =>
					import(
						/* webpackChunkName: "products" */ '../backend/products.vue'
					)
			},
			{
				path: 'products-club',
				name: 'products-club',
				component: () =>
					import(
						/* webpackChunkName: "products-club" */ '../backend/products-club.vue'
					)
			},
			{
				path: 'products/create',
				name: 'products-create',
				component: () =>
					import(
						/* webpackChunkName: "products-create" */ '../backend/product.vue'
					)
			},
			{
				path: 'products/:id',
				name: 'products-edit',
				component: () =>
					import(
						/* webpackChunkName: "products-edit" */ '../backend/product.vue'
					)
			},
			{
				path: 'pages',
				name: 'pages',
				component: () =>
					import(
						/* webpackChunkName: "pages" */ '../backend/pages.vue'
					)
			},
			{
				path: 'pages/create',
				name: 'pages-create',
				component: () =>
					import(
						/* webpackChunkName: "pages-create" */ '../backend/page.vue'
					)
			},
			{
				path: 'pages/:id',
				name: 'pages-edit',
				component: () =>
					import(
						/* webpackChunkName: "pages-edit" */ '../backend/page.vue'
					)
			},
			{
				path: 'users',
				name: 'users',
				component: () =>
					import(
						/* webpackChunkName: "users" */ '../backend/users.vue'
					)
			},
			{
				path: 'users/create',
				name: 'users-create',
				component: () =>
					import(
						/* webpackChunkName: "users-create" */ '../backend/user.vue'
					)
			},
			{
				path: 'users/:id',
				name: 'user',
				component: () =>
					import(/* webpackChunkName: "user" */ '../backend/user.vue')
			},
			{
				path: 'files',
				name: 'files',
				component: () =>
					import(
						/* webpackChunkName: "files" */ '../backend/files.vue'
					)
			},
			{
				path: 'messages',
				name: 'messages',
				component: () =>
					import(
						/* webpackChunkName: "files" */ '../backend/messages.vue'
					)
			},
			{
				path: 'messages/create',
				name: 'messages-create',
				component: () =>
					import(
						/* webpackChunkName: "messages-create" */ '../backend/message.vue'
					)
			},
			{
				path: 'messages/:id',
				name: 'messages-edit',
				component: () =>
					import(
						/* webpackChunkName: "messages-edit" */ '../backend/message.vue'
					)
			},
		]
	},

	// profile account routes

	// auth routes
	{
		path: '/auth',
		name: 'auth',
		component: () =>
			import(/* webpackChunkName: "auth" */ '../layouts/auth.vue'),
		children: [
			{
				path: '',
				name: '',
				component: () =>
					import(
						/* webpackChunkName: "auth-index" */ '../frontend/auth/index.vue'
					)
			},
			{
				path: 'login',
				name: 'login',
				component: () =>
					import(
						/* webpackChunkName: "login" */ '../frontend/auth/login.vue'
					)
			},
			{
				path: 'register',
				name: 'register',
				component: () =>
					import(
						/* webpackChunkName: "register" */ '../frontend/auth/register.vue'
					)
			},
			{
				path: 'reset',
				name: 'reset',
				component: () =>
					import(
						/* webpackChunkName: "reset" */ '../frontend/auth/reset.vue'
					)
			}
		]
	},

	// 404 error
	{
		path: '/:catchAll(.*)',
		component: () =>
			import(/* webpackChunkName: "404" */ '../frontend/404.vue')
	}
]

const router = createRouter({
	history: createWebHistory(),
	routes
})

import useAuth from '../store/auth'
import { getCurrentUser } from '../utils/firebase'

router.beforeEach(async (to, from, next) => {
	const { authUser } = useAuth()
	const requiresAuth = to.matched.some(record => record.meta.requiresAuth)
	const user = await getCurrentUser()

	//console.log(authUser.value)

	if (requiresAuth && !user) {
		next({ name: 'login' })
	} else if (to.meta.requiresAdmin && authUser.value.credentials != 'admin') {
		console.error('ACCESS DENIED!')
		next({ name: 'home' })
	} else {
		next()
	}
})

router.afterEach(async (to, from, next) => {
	window.scrollTo(0, 0)
})

export default router

import firebase from 'firebase/app'
import 'firebase/storage'
import 'firebase/auth'

// firebase credentials
const config = {
	apiKey: 'AIzaSyBI4s_F-xt-NRc8kZ84bxPdgYr7U9cUmek',
	authDomain: 'donum-app.firebaseapp.com',
	databaseURL: 'https://donum-app.firebaseio.com',
	projectId: 'donum-app',
	storageBucket: 'donum-app.appspot.com',
	messagingSenderId: '695666895443',
	appId: '1:695666895443:web:dbc0f38b3c63d9fbbcb4a6',
	measurementId: 'G-2MYEQ08MK0'
}

// initialize firebase just once
if (!firebase.apps.length) {
	firebase.initializeApp(config)
}

// export constants
export const storage = firebase.storage()
export const auth = firebase.auth()
export const authProvider = firebase.auth

import useAuth from '../store/auth'
const { afterAuthenticated } = useAuth()

export const getCurrentUser = async () => {
	return new Promise((resolve, reject) => {
		const unsubscribe = firebase.auth().onAuthStateChanged(async user => {
			if(user) {
				await afterAuthenticated(user)
				//console.log(user)
				unsubscribe()
				resolve(true)
			} else {
				//console.log('firebase: no user found')
				resolve(false)
			}
			
		}, reject)
	})
}

//export default firebase

import { reactive, toRefs, readonly } from 'vue'
import useUsers from './users'
import { useRouter } from 'vue-router'
import { useToast } from 'vue-toastification'

import { auth, authProvider } from '../utils/firebase'

const state = reactive({
	isAuthenticated: false,
	authUser: null
})

export default function useAuth() {
	const toast = useToast()
	const router = useRouter()
	const { item, create, getByEmail } = useUsers()

	// TODO: test and implement server auth
	// const login = async variable => {
	// 	console.log(variable)

	// 	if (variable.email == state.authUser.email) {
	// 		state.isAuthenticated = true
	// 		toast.success('Welcome back!')

	// 		if(state.authUser.credentials == 'admin') {
	// 			//return router.push({ name: 'dashboard' })
	// 			return window.location.replace('/admin')
	// 		}
	// 		//return router.push({ name: 'account' })
	// 		return window.location.replace('/account')
	// 	}

	// 	return toast.error('Invalid credentials!')
	// }

	const login = async variable => {
		const user = await getByEmail(variable.email)
		if(!user || user.status != 'active') {
			return //toast.info('Your account needs administrator review!')
		}

		await auth
			.signInWithEmailAndPassword(variable.email, variable.pass)
			.then(response => {
				//afterAuthenticated(response)
				//return router.push({ name: 'home' })
				return window.location.replace('/')
			})
			.catch(function(error) {
				console.warn(error)
				toast.info(error.message)
				return Promise.resolve()
			})
	}

	const afterAuthenticated = async variable => {
		if(state.isAuthenticated) return

		const user = await getByEmail(variable.email)
		if(!user || user.status != 'active') {
			return //toast.info('Your account needs administrator review!')
		} 

		state.isAuthenticated = true
		state.authUser = user

		// state.authUser = {
		// 	id: 14,
		// 	firstName: 'John',
		// 	lastName: 'Doe',
		// 	phone: '555-555-5555',
		// 	email: variable.email,
		// 	credentials: 'admin'
		// }

		// toast.success('Welcome back!')
		// return router.push({ name: 'account' })
		//return window.location.replace('/account')

		// if (user && user.credentials == 'admin') {
		// 	window.location.replace('/account')
		// } else {
		// 	router.push({ name: 'home' })
		// }

		return Promise.resolve(user)
	}

	const logout = async variable => {
		if(variable == 'admin') {
			window.location.replace('/auth/login')
		} else {
			router.push({ name: 'login' })
		}

		auth.signOut()
		state.isAuthenticated = false
		state.authUser = null

		//toast.success('Disconnected!')
		//return router.push({ name: 'login' })
		//return window.location.replace('/auth/login')
	}

	const register = async (variable) => {
		await auth
			.createUserWithEmailAndPassword(variable.email, variable.pass)
			.then(response => {
				delete variable.pass
				create(variable, 'register')

				toast.success('Your account is created!')
				//return router.push({ name: 'home' })
				//return window.location.replace('/auth/login')
			})
			.catch(error => {
				console.warn(error)
				toast.info(error.message)
				return Promise.resolve()
			})
	}

	const reset = async variable => {
		await auth.sendPasswordResetEmail(variable)
			.then(res => {
				toast.success('Email sent. Check also in spam folder!')
				return router.push({ name: 'login' })
				return Promise.resolve()
			})
			.catch(error => {
				console.error(error)
				toast.info(error.message)
				return Promise.reject()
			})

	}

	// create client from order
	const createClientAccount = async (variable) => {
		await auth
			.createUserWithEmailAndPassword(variable.email, variable.pass)
			.then(response => {
				console.log('Client account is created in firebase!')
			})
			.catch(error => {
				console.warn(error)
				return Promise.resolve('error')
			})
	}

	return {
		...toRefs(readonly(state)),
		login,
		logout,
		register,
		reset,
		createClientAccount,
		afterAuthenticated
	}
}

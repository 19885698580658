import { reactive, toRefs, readonly } from 'vue'
import graphql from '../graphql/graphql'
import * as gql from '../graphql/messages.gql'

import { storage } from '../utils/firebase'
import { useToast } from 'vue-toastification'

const state = reactive({
	items: null,
	item: null,
})

export default function useMessages() {
	const toast = useToast()

	const getAll = async () => {
		if (state.items) return

		try {
			const data = await graphql.request(gql.all)
			state.items = data.messages
		} catch (error) {
			console.error(error)
			toast.error(error.message)
		}
	}

	const getOne = async variable => {
		// search locally
		if (state.items) {
			let item = state.items.find(item => item.id == variable)
			if (item) {
				console.log(item);
				return (state.item = item)
			}
		}

		// search online
		try {
			const data = await graphql.request(gql.one, { id: variable })
			state.item = data.messages_by_pk
		} catch (error) {
			console.error(error)
			toast.error(error.message)
		}
	}

	const create = async variable => {
		try {
			delete variable.id
			const data = await graphql.request(gql.create, {
				object: variable
			})
			if (state.items) {
				state.items.push({ ...variable, id: data.insert_messages_one.id })
			}

			toast.success('Created')
			return Promise.resolve('success')
		} catch (error) {
			console.error(error)
			toast.error(error.message)
			return Promise.reject('error')
		}
	}

	const update = async variable => {
		try {
			// 1. update database
			await graphql.request(gql.update, {
				_set: variable,
				pk_columns: { id: variable.id }
			})

			// 2. update state
			if (state.items) {
				state.items[
					state.items.findIndex(obj => obj.id == variable.id)
				] = variable
			}

			// 3. notify and return promise
			toast.success('Updated')
			return Promise.resolve('success')
		} catch (error) {
			console.error(error)
			toast.error(error.message)
			return Promise.reject('error')
		}
	}

	const destroy = async variable => {
		if (!confirm('Are you sure?')) return

		try {
			const data = await graphql.request(gql.delete, {
				id: variable.id
			})
			state.items = state.items.filter(item => item.id != variable.id)
			toast('Deleted')
		} catch (error) {
			console.error(error)
			toast.error(error.message)
		}
	}

	return {
		...toRefs(readonly(state)),
		getAll,
		getOne,
		create,
		update,
		destroy
	}
}

import { reactive, toRefs, readonly } from 'vue'
import graphql from '../graphql/graphql'
import * as gql from '../graphql/users.gql'

import { useToast } from 'vue-toastification'

//import useFiles from '../store/files'

const state = reactive({
	items: null,
	item: null,
	// for pagination:
	limit: 100,
	offset: 0,
	totalCount: 0
})

export default function useUsers() {
	const toast = useToast()

	const getAll = async () => {
		if (state.items) return

		try {
			const data = await graphql.request(gql.all)
			state.items = data.users
		} catch (error) {
			console.error(error)
			toast.error(error.message)
		}
		return Promise.resolve()
	}

	const getPaginated = async (variable) => {
		if (state.items && state.items.length >= state.limit) return

		try {
			const data = await graphql.request(gql.paginated, {
				limit: state.limit,
				offset: state.offset
			})
			state.items = data.users
			state.totalCount = data.users_aggregate.aggregate.totalCount
			//console.log(data.users_aggregate.aggregate.totalCount)
		} catch (error) {
			console.error(error)
			toast.error(error.message)
		}
	}

	const getOne = async (variable) => {
		// search locally
		if (state.items) {
			let item = state.items.find((item) => item.id == variable)
			if (item) {
				return (state.item = item)
			}
		}

		// search online
		try {
			const data = await graphql.request(gql.one, { id: variable })
			state.item = data.users_by_pk
		} catch (error) {
			console.error(error)
			toast.error(error.message)
		}
	}

	const getByEmail = async (variable) => {
		// search locally
		if (state.items) {
			let item = state.items.find((item) => item.email == variable)
			if (item) {
				return (state.item = item)
			}
		}

		// search online
		try {
			const data = await graphql.request(gql.byEmail, { email: variable })
			state.item = data.users[0]
			return data.users[0]
		} catch (error) {
			console.error(error)
			toast.error(error.message)
		}
	}

	const create = async (variable, scope = null) => {
		try {
			delete variable.id
			const data = await graphql.request(gql.create, {
				object: { ...variable, credentials: 'client', status: 'active' }
			})
			if (state.items) {
				state.items.push({ ...variable, id: data.insert_users_one.id })
			}

			if (!scope) toast.success('Created')

			return Promise.resolve('success')
		} catch (error) {
			console.error(error)
			toast.error(error.message)
			return Promise.reject('error')
		}
	}

	const createClient = async (variable, scope = null) => {
		try {
			const data = await graphql.request(gql.create, {
				object: { ...variable, credentials: 'client', status: 'active' }
			})
			return Promise.resolve('success')
		} catch (error) {
			console.error(error)
			return Promise.resolve('error')
		}
	}

	const update = async (variable) => {
		try {
			//return console.log(variable)

			// 1. update database
			const data = Object.assign({}, variable)
			delete data.image

			const result = await graphql.request(gql.update, {
				_set: data,
				pk_columns: { id: variable.id }
			})

			// 2. update state
			if(state.items){
			state.items[state.items.findIndex((obj) => obj.id == variable.id)] =
				variable
			}
			// 3. notify and return promise
			toast.success('Updated')
			return Promise.resolve('success')
		} catch (error) {
			console.error(error)
			toast.error(error.message)
			return Promise.reject('error')
		}
	}

	const updateProfile = async (variable) => {
		try {
			const data = Object.assign({}, variable)
			delete data.image

			await graphql.request(gql.update, {
				_set: data,
				pk_columns: { id: variable.id }
			})

			console.log('saved')

			// 2. update state
			state.item = variable

			// 3. notify and return promise
			return Promise.resolve('success')
		} catch (error) {
			console.error(error)
			toast.error(error.message)
			return Promise.reject('error')
		}
	}

	const updateMany = async (variable) => {
		try {
			const data = await graphql.request(gql.updateMany, variable)
			console.log('TODO: update local state also')
			//console.log(data.updateManyUsers.count)
		} catch (error) {
			console.error(error)
			toast.error(error.message)
		}
	}

	const destroy = async (variable) => {
		if (!confirm('Are you sure?')) return

		try {
			const data = await graphql.request(gql.delete, { id: variable.id })
			state.items = state.items.filter((item) => item.id != variable.id)
			toast('Deleted')
		} catch (error) {
			console.error(error)
			toast.error(error.message)
		}
	}

	const destroyMany = async (variable) => {
		if (!confirm('Are you sure?')) return

		try {
			const data = await graphql.request(gql.deleteMany, variable)
			state.items = state.items.filter((item) => item.id != variable.id)
		} catch (error) {
			console.error(error)
			toast.error(error.message)
		}
	}

	const updateImage = async (image, itemId) => {
		// 1. update products database
		await graphql.request(gql.update, {
			_set: { imageId: image.id },
			pk_columns: { id: itemId }
		})

		// 2. update state item
		state.item.imageId = image.id
		state.item.image = { url: image.url }

		// 3. update state items
		if (state.items.length) {
			state.items[state.items.findIndex((obj) => obj.id == itemId)] =
				state.item
		}
		console.log(state.item.image.url)
	}

	const deleteImage = async (imageUrl, imageId, itemId) => {
		if (!confirm('Are you sure you want to delete this?')) return

		// also delete file from files table
		// const { destroy: deleteFile } = useFiles()
		// const res = await deleteFile({ id: imageId, url: imageUrl }).then(
		// 	res => {
		// 		return res
		// 	}
		// )
		// if (res == 'cancelled') return

		// 1. update products database
		await graphql.request(gql.update, {
			_set: { imageId: null },
			pk_columns: { id: itemId }
		})

		// 2. update state item
		state.item.imageId = null
		state.item.image = null

		// 3. update state items
		if (state.items) {
			state.items[state.items.findIndex((obj) => obj.id == itemId)] =
				state.item
		}
		toast.success('Image deleted')
		//console.log(state.item.image.url)
	}

	const loadMore = async (variable) => {
		console.log(variable)
		if (variable) {
			state.limit = state.limit + variable
		} else {
			state.limit = state.limit + state.limit
		}

		await getPaginated()
	}

	return {
		...toRefs(readonly(state)),
		getAll,
		getPaginated,
		getOne,
		getByEmail,
		create,
		createClient,
		update,
		updateMany,
		destroy,
		loadMore,
		destroyMany,
		updateImage,
		updateProfile,
		deleteImage
	}
}

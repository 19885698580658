import { reactive, toRefs, readonly } from 'vue'
import graphql from '../graphql/graphql'
import * as gql from '../graphql/orders.gql'
import useAuth from './auth'

import { useToast } from 'vue-toastification'

const state = reactive({
	model: 'orders',
	items: null,
	item: null,
	selected: [],
	// for pagination:
	limit: 1000,
	offset: 0,
	totalCount: 0
})

export default function useOrders() {
	const { isAuthenticated, authUser } = useAuth()
	const toast = useToast()

	const getAll = async () => {
		if (state.items) return
		let query
		if(window.location.href.indexOf("orders-club") > -1){
			query = gql.allIsClub
			console.log('1');
		}else{
			query = gql.all
			console.log('2');
		}
		try {
			const data = await graphql.request(query)
			state.items = data.orders
		} catch (error) {
			console.error(error)
			toast.error(error.message)
		}
	}

	const getPaginated = async (variable) => {
		if (state.items && state.items.length >= state.limit) return

		try {
			const data = await graphql.request(gql.paginated, {
				limit: state.limit,
				offset: state.offset
			})
			state.items = data.orders
			state.totalCount = data.orders_aggregate.aggregate.totalCount
			//console.log(data.users_aggregate.aggregate.totalCount)
		} catch (error) {
			console.error(error)
			toast.error(error.message)
		}
	}

	const getUserOrders = async () => {
		if (state.items) return

		try {
			const data = await graphql.request(gql.userOrders, {
				where: { owner: { _eq: authUser.value.email } }
			})
			state.items = data.orders
		} catch (error) {
			console.error(error)
			toast.error(error.message)
		}
	}

	const getOne = async (variable) => {
		// search locally
		if (state.items) {
			let item = state.items.find((item) => item.id == variable)
			if (item) {
				return (state.item = item)
			}
		}

		// search online
		try {
			const data = await graphql.request(gql.one, { id: variable })
			state.item = data.orders_by_pk
			return data.orders_by_pk
		} catch (error) {
			console.error(error)
			toast.error(error.message)
		}
	}

	const create = async (variable) => {
		try {
			const data = await graphql.request(gql.create, {
				object: variable
			})

			state.item = data.insert_orders_one

			localStorage.setItem(
				'orderPending',
				JSON.stringify(data.insert_orders_one.id)
			)

			return Promise.resolve(data.insert_orders_one)
		} catch (error) {
			console.error(error)
			toast.error(error.message)
			return Promise.reject('error')
		}
	}

	const destroy = async (variable) => {
		if (!confirm('Are you sure?')) return

		try {
			const data = await graphql.request(gql.delete, {
				id: variable
			})
			state.items = state.items.filter((item) => item.id != variable)
			toast('Deleted')
		} catch (error) {
			console.error(error)
			toast.error(error.message)
		}
	}

	const redeem = async (variable) => {
		try {
			const data = await graphql.request(gql.update, {
				_set: { status: 'claimed', receiver: variable.form },
				pk_columns: { id: variable.orderId }
			})
			state.item.status = 'claimed'
			console.log('gift redeemed...')
			return Promise.resolve('success')
		} catch (error) {
			console.error(error)
			toast.error(error.message)
			return Promise.reject('error')
		}
	}

	const refuse = async (variable) => {
		if (!confirm('Are you sure?')) return
		try {
			const data = await graphql.request(gql.update, {
				_set: { status: 'refused' },
				pk_columns: { id: variable.orderId }
			})
			state.item.status = 'refused'
			return Promise.resolve('success')

			console.log('gift refused...')
		} catch (error) {
			console.error(error)
			toast.error(error.message)
			return Promise.reject('error')
		}
	}

	const finalize = async (variable) => {
		//return console.log(variable)

		if (!confirm('Are you sure?')) return
		try {
			const data = await graphql.request(gql.update, {
				_set: { status: 'finalized' },
				pk_columns: { id: variable }
			})
			state.item.status = 'finalized'
			if (state.items && state.items.length) {
				state.items[
					state.items.findIndex((obj) => obj.id == variable)
				] = state.item
			}

			toast.success('Finalized')
			return Promise.resolve('success')
		} catch (error) {
			console.error(error)
			toast.error(error.message)
			return Promise.reject('error')
		}
	}

	const refund = async (variable) => {
		//return console.log(variable)

		if (!confirm('Are you sure?')) return
		try {
			const data = await graphql.request(gql.update, {
				_set: { status: 'refunded' },
				pk_columns: { id: variable }
			})
			state.item.status = 'refunded'
			if (state.items && state.items.length) {
				state.items[
					state.items.findIndex((obj) => obj.id == variable)
				] = state.item
			}

			toast.success('Refunded')
			return Promise.resolve('success')
		} catch (error) {
			console.error(error)
			toast.error(error.message)
			return Promise.reject('error')
		}
	}

	const markPaymentSuccessfully = async (variable) => {
		console.log(variable)
		//return console.log(variable)
		const payment_intent = JSON.parse(
			localStorage.getItem('payment_intent')
		)

		try {
			const data = await graphql.request(gql.update, {
				_set: {
					status: 'payment-success',
					paymentSessionId: variable.sessionIdRequest,
					payment_intent: payment_intent
				},
				pk_columns: { id: variable }
			})
			//await createInvoice(variable)

			toast.success('Payment Finalized')
			return Promise.resolve('success')
		} catch (error) {
			console.error(error)
			toast.error(error.message)
			return Promise.reject('error')
		}
	}

	const markPaymentSuccessfullyClub = async (variable) => {
		try {
			const data = await graphql.request(gql.update, {
				_set: { status: 'payment-success-club' },
				pk_columns: { id: variable }
			})

			toast.success('Payment Finalized')
			return Promise.resolve('success')
		} catch (error) {
			console.error(error)
			toast.error(error.message)
			return Promise.reject('error')
		}
	}

	const getOrderByStripeSession = async (variable) => {
		try {
			const data = await graphql.request(gql.getOrderByStripeSession, {
				id: variable
			})
			return data.orders[0].id
		} catch (error) {
			console.error(error)
			toast.error(error.message)
			return Promise.reject('error')
		}
	}

	// const createInvoice = async (variable) => {
	// 	let userId = authUser.value.id || null
	// 	try {
	// 		const data = await graphql.request(gql.createInvoice, {
	// 			object: {order_id: variable, user_id: userId}
	// 		})
	// 		console.log('invoice created')
	// 	} catch (error) {
	// 		console.error(error)
	// 		return Promise.reject('error')
	// 	}
	// }

	const paymenFailed = async (variable) => {
		//return console.log(variable)

		try {
			const data = await graphql.request(gql.update, {
				_set: { status: 'payment-failed' },
				pk_columns: { id: variable }
			})

			toast('Payment Failed')
			return Promise.resolve('success')
		} catch (error) {
			console.error(error)
			toast.error(error.message)
			return Promise.reject('error')
		}
	}

	const totalPrice = (variable) => {
		return variable
			.map((item) => item.price * item.quantity)
			.reduce((prev, next) => prev + next)
			.toFixed(2)
	}

	const loadMore = async (variable) => {
		console.log(variable)
		if (variable) {
			state.limit = state.limit + variable
		} else {
			state.limit = state.limit + state.limit
		}

		await getPaginated()
	}

	const findInvoice = async (variable) => {}

	return {
		...toRefs(state),
		getAll,
		getOne,
		getUserOrders,
		create,
		destroy,
		redeem,
		refuse,
		finalize,
		refund,
		totalPrice,
		markPaymentSuccessfully,
		markPaymentSuccessfullyClub,
		getOrderByStripeSession,
		paymenFailed,
		getPaginated,
		loadMore
	}
}

import { setHeaders, setHeader, GraphQLClient } from 'graphql-request'

const endpoint = process.env.VUE_APP_GRAPHQL_ENDPOINT
const secret = process.env.VUE_APP_GRAPHQL_AUTH

const graphql = new GraphQLClient(endpoint)

// Set a single header
graphql.setHeader('x-hasura-admin-secret', secret)

// Override all existing headers
// client.setHeaders({
//     authorization: 'Bearer MY_TOKEN',
//     anotherheader: 'header_value'
// })

export default graphql
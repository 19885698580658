import { reactive, toRefs, readonly } from 'vue'
import useOrders from './orders'
import useMessages from './messages'

import useAuth from './auth'
import { useRouter } from 'vue-router'
import { useToast } from 'vue-toastification'

import { loadStripe } from '@stripe/stripe-js'

const state = reactive({
	items: [],
	item: null,
	sender: null,
	receiver: null,
	messages: []
})

export default function useCart() {
	const { isAuthenticated, authUser } = useAuth()
	const router = useRouter()
	const toast = useToast()
	const { create } = useOrders()
	const { items, getAll } = useMessages()
	getAll()
	const messages = items.value

	const addToCart = async (item) => {
		if (inCart(item)) return

		let price = item.priceFull
		if (item.priceReduced) {
			price = item.priceReduced
		}

		fbq('track','AddToCart', {
			content_name: item.title,
			content_ids: item.id,
			content_type: 'product',
			value: price,
			currency: 'RON'
		});

		state.items.push({ ...item, price: price })

		localStorage.setItem('tempCart', JSON.stringify(state.items))

		//toast.success('Added')
	}

	const restoreCart = (item) => {
		// restore the cart on page refresh
		const tempCart = localStorage.getItem('tempCart')
		if (tempCart && tempCart.length) {
			state.items = JSON.parse(tempCart)
		}

		const tempSender = JSON.parse(localStorage.getItem('tempSender'))
		if (tempSender) {
			state.sender = tempSender.sender
		}
	}

	const inCart = (item) => {
		let product = state.items.find((i) => i.id == item.id)
		return product ? true : false
	}

	const placeOrder = async () => {
		const products = []

		state.items.forEach((element) => {
			let product = {
				id: element.id,
				image: element.image,
				title: element.title,
				price: element.price,
				quantity: element.quantity
			}

			fbq('track','InitiateCheckout', {
				content_name: product.title,
				content_ids: product.id,
				content_type: 'product',
				value: product.price,
				currency: 'RON'
			});

			return products.push(product)
		})

		// create pending order in database
		let data = {
			status: 'pending',
			sender: {
				...state.sender,
				fullName: state.sender.firstName + ' ' + state.sender.lastName
			},
			products: products,
			owner: state.sender.email,
			totalPrice: totalPrice(products),
			custom_message: state.sender.message
		}
		const newOrder = await create(data)

		// test in production:
		//const apikey = process.env.VUE_APP_API_STRIPE_KEY_TEST

		// live in production:
		const apikey =
			process.env.NODE_ENV === 'production'
				? process.env.VUE_APP_API_STRIPE_KEY_LIVE
				: process.env.VUE_APP_API_STRIPE_KEY_TEST

		const stripe = await loadStripe(apikey)

		await fetch(process.env.VUE_APP_API_STRIPE, {
			method: 'POST',
			headers: {
				'Content-Type': 'application/json',
				'Access-Control-Allow-Origin': '*'
			},
			body: JSON.stringify({
				orderId: newOrder.id,
				products: products
			})
		})
			.then(function (response) {
				return response.json()
			})
			.then(function (session) {
				localStorage.setItem(
					'sessionIdRequest',
					JSON.stringify(session.id)
				)

				localStorage.removeItem('tempCart')

				return stripe.redirectToCheckout({
					sessionId: session.id
				})
			})
			.then(function (result) {
				// If `redirectToCheckout` fails due to a browser or network
				// error, you should display the localized error message to your
				// customer using `error.message`.
				if (result.error) {
					alert(result.error.message)
				}
			})
			.catch(function (error) {
				console.error('Error:', error)
			})
	}

	const placeOrderClub = async (item,sender) => {
		const products = []
		products.push(item)

		if (isAuthenticated.value === true) {
			// let sender = { ...authUser.value, message: variable.sender.message }
			let sender = {
				...authUser.value,
				message: localStorage.getItem('custom_message')
			}
			state.sender = sender
			localStorage.setItem(
				'tempSender',
				JSON.stringify({ sender: sender })
			)
		} else {
			state.sender = sender
			state.sender.message = localStorage.getItem('custom_message')
			localStorage.setItem('tempSender', JSON.stringify({ sender: sender }))
		}

		localStorage.setItem('image_url', item.image.url)
		localStorage.setItem('clientEmail', state.sender.email)

		// create pending order in database
		let data = {
			status: 'pending',
			sender: {
				...state.sender,
				fullName: state.sender.firstName + ' ' + state.sender.lastName
			},
			products: products,
			owner: state.sender.email,
			totalPrice: item.priceFull,
			custom_message: localStorage.getItem('custom_message'),
			is_club: '1'
		}

		const newOrder = await create(data)

		const apikey =
			process.env.NODE_ENV === 'production'
				? process.env.VUE_APP_API_STRIPE_KEY_LIVE
				: process.env.VUE_APP_API_STRIPE_KEY_TEST

		const stripe = await loadStripe(apikey)

		await fetch(process.env.VUE_APP_API_STRIPE, {
			method: 'POST',
			headers: {
				'Content-Type': 'application/json',
				'Access-Control-Allow-Origin': '*'
			},
			body: JSON.stringify({
				orderId: newOrder.id,
				products: products
			})
		})
		.then(function (response) {
			return response.json()
		})
		.then(function (session) {
			localStorage.setItem(
				'sessionIdRequest',
				JSON.stringify(session.id)
			)

			return stripe.redirectToCheckout({
				sessionId: session.id
			})
		})
		.then(function (result) {
			// If `redirectToCheckout` fails due to a browser or network
			// error, you should display the localized error message to your
			// customer using `error.message`.
			if (result.error) {
				alert(result.error.message)
			}
		})
		.catch(function (error) {
			console.error('Error:', error)
		})
	}

	const quantityPlus = (item) => {
		state.items.find((i) => i.id === item.id).quantity++
		localStorage.setItem('tempCart', JSON.stringify(state.items))
	}

	const quantityMinus = (item) => {
		let product = state.items.find((i) => i.id === item.id)

		product.quantity--

		if (product.quantity == 0) {
			removeFromCart(item)
		}

		localStorage.setItem('tempCart', JSON.stringify(state.items))
	}

	const quantitySet = (item) => {
		state.items.find((i) => i.id === item.id).quantity == item.quantity
		localStorage.setItem('tempCart', JSON.stringify(state.items))
	}

	const removeFromCart = async (item) => {
		if (!confirm('Are you sure?')) {
			let product = state.items.find((i) => i.id === item.id)

			// product.quantity++

			return Promise.reject('error')
		}

		state.items = state.items.filter((i) => i.id !== item.id)
		localStorage.setItem('tempCart', JSON.stringify(state.items))
		return Promise.resolve('success')
	}

	const giftValue = () => {
		let subtotal = state.items
			.map((item) => item.priceFull * 100/12 * item.quantity)
			.reduce((prev, next) => prev + next)
			.toFixed(2)
		return Number(subtotal)
	}

	const totalPrice = () => {
		let subtotal = state.items
			.map((item) => item.price * item.quantity)
			.reduce((prev, next) => prev + next)
			.toFixed(2)

		let fee = subtotal * (12 / 100)
		let total = subtotal
		return Number(total) + Number(fee)
	}

	const totalPriceClub = () => {
		let subtotal = state.items
			.map((item) => item.price * item.quantity)
			.reduce((prev, next) => prev + next)
			.toFixed(2)

		let fee = 0
		let total = subtotal
		return Number(total) + Number(fee)
	}

	const donumFee = () => {
		let subtotal = state.items
			.map((item) => item.price * 100/12 * item.quantity)
			.reduce((prev, next) => prev + next)
			.toFixed(2)

		let fee = Number(subtotal) * (12 / 100)
		return fee.toFixed(2)
	}

	const next = (variable) => {
		if (isAuthenticated.value === true) {
			// let sender = { ...authUser.value, message: variable.sender.message }
			let sender = {
				...authUser.value,
				message: localStorage.getItem('custom_message')
			}
			state.sender = sender
			localStorage.setItem(
				'tempSender',
				JSON.stringify({ sender: sender })
			)
		} else {
			state.sender = variable.sender
			state.sender.message = localStorage.getItem('custom_message')
			localStorage.setItem('tempSender', JSON.stringify(variable))
		}
		// console.log(state.sender.message);

		// if (variable.createAccount) {
		// 	localStorage.setItem('createAccountFromOrder', JSON.stringify(variable))
		// }

		return router.push('/cart/payment')
	}

	const afterPayment = () => {}

	return {
		...toRefs(readonly(state)),
		addToCart,
		removeFromCart,
		inCart,
		restoreCart,
		placeOrder,
		placeOrderClub,
		quantityPlus,
		quantityMinus,
		quantitySet,
		totalPrice,
		totalPriceClub,
		donumFee,
		giftValue,
		next,
		messages
	}
}

<template>
	<router-view />
</template>

<script>
export default {
	name: 'app'
}
</script>


import { createApp } from 'vue'
import app from './App.vue'
import router from './router'

import Toast from 'vue-toastification'

/* VUE MASONRY */
import MasonryWall from '@yeger/vue-masonry-wall'

import 'vue-toastification/dist/index.css'

const vue = createApp(app)

import useFilters from './utils/filters'
vue.config.globalProperties.$filters = {
	...useFilters()
}

import useAuth from './store/auth'
vue.config.globalProperties.$auth = {
    ...useAuth()
}

vue.config.globalProperties.$toast = {
	// TODO: use toast global !?
}

vue.config.globalProperties.$settings = {
	// TODO: use toast global !?
}

import { createHead } from '@vueuse/head'
const head = createHead()
vue.use(head)

// import useCrud from './utils/crud'
// const {all} = useCrud('model')
// let i = all()
// console.log(i)

// restore the cart on page refresh
import useCart from './store/cart'
const { restoreCart } = useCart()
restoreCart()

import PrimeVue from 'primevue/config'
vue.use(PrimeVue)

import DataTable from 'primevue/datatable'
import Column from 'primevue/column'
vue.component('DataTable', DataTable)
vue.component('Column', Column)

import InputText from 'primevue/inputtext';
vue.component('InputText', InputText)

import RadioButton from 'primevue/radiobutton';
vue.component('RadioButton', RadioButton)

vue.use(Toast)

/* VUE MASONRY  */
vue.use(MasonryWall)

vue.use(router)
router.isReady().then(() => vue.mount('#app'))
